var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qr-entries-table pb-3"},[_c('v-data-table',{staticClass:"overflow-x elevation-1",attrs:{"height":"100%","item-key":"name","hide-default-footer":"","headers":_vm.headers,"items":_vm.computedItems,"loading":_vm.loading,"page":_vm.options.page,"options":_vm.options,"server-items-length":_vm.options.itemsLength},on:{"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{staticClass:"justify-end pa-3 border-bottom",attrs:{"pagination":pagination,"options":options,"items-per-page-text":"","items-per-page-options":[],"disable-items-per-page":"","disable-pagination":_vm.loading,"show-first-last-page":true},on:{"update:options":updateOptions},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"d-flex flex-sm-grow-1 align-center justify-start"},[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary","small":"","depressed":""},on:{"click":function($event){_vm.showModalCreate = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"body-2 font-weight-light"},[_vm._v("Создать")])],1),_c('div',{staticClass:"search"},[_c('v-text-field',{staticClass:"border-all",attrs:{"placeholder":"Название въезда","solo":"","dense":"","flat":"","hide-details":""},on:{"change":_vm.updateQuery},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]},proxy:true}],null,true)})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.entry_type_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("getCatalogValue")(item.entry_type_id,"entrances_types"))+" ")])]}},{key:"item.houses",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getAddress(item)))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editEntry(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)}),(_vm.showModalCreate)?_c('QrTableEntriesModal',{on:{"update-info":_vm.updateQuery,"close-modal":function($event){_vm.showModalCreate = false}}}):_vm._e(),(_vm.showModalEdit)?_c('QrTableEntriesModal',{attrs:{"toEdit":_vm.toEdit},on:{"update-info":_vm.updateQuery,"close-modal":function($event){_vm.showModalEdit = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }